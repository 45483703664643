import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { deserialize } from 'deserialize-json-api'

import SEO from '../../atoms/Seo'
import JsonLD from '../../atoms/JsonLD'
import Navbar from '../../organisms/Navbar'
import Footer from '../../organisms/NewFooter'
import ProgramPage from '../../organisms/ProgramPage'
import { API_SALES_URL } from '../../../config'
import { deepReplace, replaceGraphKey } from '../../../utils/deepReplace'
import { transformProgram, useSiteMetadata } from '../../../utils/seoTools'

const ProgramTemplate = ({ id, location }) => {
  const [program, updateProgram] = useState(null)
  const [loading, updateLoading] = useState(false)

  const site = useSiteMetadata()
  const { webpage, organization } = deepReplace(site, replaceGraphKey)

  const fetchProgram = useCallback(async () => {
    updateLoading(true)

    try {
      const { data } = await axios.get(`${API_SALES_URL}/api/programs/${id}`)

      updateProgram(get(deserialize(data), 'data'))
    } catch (err) {
      console.log(err)
    } finally {
      updateLoading(false)
    }
  }, [updateLoading, updateProgram])

  useEffect(() => {
    fetchProgram()
  }, [])

  const title = 'Programme Neuf par Folhomee'
  const description = 'Folhomee vous propose en exclusivité les meilleurs programmes neufs dans toutes la France'

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
          <JsonLD>
            {[webpage, organization, transformProgram(program)]}
          </JsonLD>
        </SEO>
      <Navbar location={location} />
      <ProgramPage loading={loading} program={program} />
      <Footer
        noImage={true}
        noForm={true} />
    </>
  )
}

ProgramTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.object
}

export default ProgramTemplate
