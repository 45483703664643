import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { map, size, isEqual, get } from 'lodash'

import theme from '../../../config/theme'
import media from '../../../utils/media'

const BaseContainer = styled.div`
  margin-top: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${media.lessThan('sm')`
    margin-top: 0;
  `}
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled(FontAwesomeIcon)`
  cursor: pointer;
  z-index: 99;
  font-size: 24px;

  &:first-of-type {
    margin-right: 8px;
  }

  &:last-of-type {
    margin-left: 8px;
  }

  ${media.lessThan('sm')`
    display: none;
  `}
`

const ImageContainer = styled.div`
  width: ${({ width }) => width}px;
  height: 400px;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  position: relative;

  ${media.lessThan('sm')`
    width: 100%;
  `}
`

const DotsContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('sm')`
    margin-top: 0;
  `}
`

const Dot = styled.div`
  z-index: 99;
  height: 12px;
  width: 12px;
  opacity: ${({ isCurrent }) => isCurrent ? 1 : 0.5};
  background-color: ${({ theme }) => get(theme, 'blue')};
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  margin: 0 15px;
`

const ProgramCarousel = ({
  imgs, isHover, updateIsHover, children, step, updateStep, width
}) => {
  const increment = () => updateStep((step + 1) % size(imgs))
  const decrement = () => {
    const newStep = (step - 1) % size(imgs)

    if (newStep < 0) {
      return updateStep(size(imgs) - 1)
    }

    return updateStep(newStep)
  }

  useEffect(() => {
    const timer = window.setInterval(() => {
      if (!isHover) {
        return updateStep((step + 1) % size(imgs))
      }
    }, 5000)

    return () => {
      window.clearInterval(timer)
    }
  }, [imgs, step, isHover])

  const handlers = useSwipeable({
    onSwipedLeft: decrement,
    onSwipedRight: increment,
    preventDefaultTouchmoveEvent: true
  })

  return (
    <BaseContainer>
      <Container>
        <Button
          icon='chevron-left'
          color={get(theme, 'blue')}
          onClick={decrement}
          onMouseEnter={() => updateIsHover(true)}
          onMouseLeave={() => updateIsHover(false)} />
        <ImageContainer
          {...handlers}
          width={width}
          onMouseEnter={() => updateIsHover(true)}
          onMouseLeave={() => updateIsHover(false)}>
          {children}
        </ImageContainer>
        <Button
          icon='chevron-right'
          color={get(theme, 'blue')}
          onClick={increment}
          onMouseEnter={() => updateIsHover(true)}
          onMouseLeave={() => updateIsHover(false)} />
      </Container>
      <DotsContainer
        {...handlers}
        onMouseEnter={() => updateIsHover(true)}
        onMouseLeave={() => updateIsHover(false)}>
        {map(imgs, (_, idx) => (
          <Dot
            key={`dot-${idx}`}
            isCurrent={isEqual(idx, step)}
            onClick={() => updateStep(idx)}
            onMouseEnter={() => updateIsHover(true)}
            onMouseLeave={() => updateIsHover(false)} />
        ))}
      </DotsContainer>
    </BaseContainer>
  )
}

ProgramCarousel.propTypes = {
  step: PropTypes.number.isRequired,
  imgs: PropTypes.array.isRequired,
  width: PropTypes.number,
  isHover: PropTypes.bool.isRequired,
  updateStep: PropTypes.func.isRequired,
  updateIsHover: PropTypes.func.isRequired
}

ProgramCarousel.defaultProps = {
  width: 810
}

export default ProgramCarousel

