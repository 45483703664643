import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { StaticImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import Rooms from '../../atoms/Rooms'
import Fiscal from '../../atoms/Fiscal'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'
import { getDeliveryString } from '../../../utils/string'

const Container = styled.div`
  display: flex;
  margin-top: 26px;
  border: 1px solid ${({ theme }) => get(theme, 'blue')};
  padding: 24px 16px;
  border-radius: 10px;

  ${media.lessThan('sm')`
    padding: 8px;
    flex-direction: column;
    justify-content: center;
  `}
`

const DataPart = styled.div`
  display: flex;
  align-items: center;
  margin-right: 28px;

  ${media.lessThan('sm')`
    margin-top: 16px;
    margin-right: 0;
  `}
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const ProgramDescription = ({ delivery, taxation, lots }) => (
  <Container>
    <DataPart>
      <ImageContainer>
        <StaticImage src='../../../images/delivery.png' alt='livraison programme' />
      </ImageContainer>
      <HomeSectionDescription>
        Livraison au {getDeliveryString(delivery)}
      </HomeSectionDescription>
    </DataPart>
    <DataPart>
      <ImageContainer>
        <StaticImage src='../../../images/surface.png' alt='surface programme' />
      </ImageContainer>
      <Rooms lots={lots} />
    </DataPart>
    <DataPart>
      <Fiscal taxation={taxation} />
    </DataPart>
  </Container>
)

ProgramDescription.propTypes = {
  lots: PropTypes.array,
  taxation: PropTypes.string,
  delivery: PropTypes.string
}

ProgramDescription.defaultProps = {
  lots: [],
  taxation: '',
  delivery: ''
}

export default ProgramDescription
