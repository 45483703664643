import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, map } from 'lodash'

import media from '../../../utils/media'
import CityForm from '../../molecules/CityForm'
import ProgramMap from '../../molecules/ProgramMap'
import ProgramLots from '../../molecules/ProgramLots'
import ProgramData from '../../molecules/ProgramData'
import ProgramTitle from '../../molecules/ProgramTitle'
import LoadingProgram from '../../atoms/LoadingProgram'
import ProgramCarousel from '../../molecules/ProgramCarousel'
import ProgramDescription from '../../molecules/ProgramDescription'

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 80px 60px;
  justify-content: center;

  ${media.lessThan('xl')`
    flex-direction: column;
    align-items: center;
    padding: 20px 8px;
  `}
`

const ProgramContentContainer = styled.div`
  width: 860px;
  display: flex;
  flex-direction: column;
  margin-right: 80px;

  ${media.lessThan('xl')`
    margin: 0;
    width: 100%;
  `}
`

const ItemContainer = styled.div`
  padding: 0 24px;

  ${media.lessThan('xl')`
    &:first-of-type {
      padding-top: 64px;
    }
  `}
`

const DesktopItemContainer = styled(ItemContainer)`
  display: block;

  ${media.lessThan('xl')`
    display: none;
  `}
`

const MobileItemContainer = styled(ItemContainer)`
  display: none;

  ${media.lessThan('xl')`
    margin-top: 24px;
    display: block;
  `}
`

const ProgramContent = ({ loading, program }) => {
  if (loading) {
    return (
      <LoadingProgram loading={loading} />
    )
  }

  return (
    <>
      <DesktopItemContainer>
        <ProgramTitle
          city={get(program, 'city')}
          name={get(program, 'name')} />
      </DesktopItemContainer>
      <ProgramCarousel imgs={map(get(program, 'images'), 'url')} />
      <MobileItemContainer>
        <ProgramTitle
          city={get(program, 'city')}
          name={get(program, 'name')} />
      </MobileItemContainer>
      <ItemContainer>
        <ProgramData
          lots={get(program, 'lots')}
          taxation={map(get(program, 'taxations'), 'name')}
          delivery={get(program, 'delivery_date')} />
      </ItemContainer>
      <ItemContainer>
        <ProgramDescription description={get(program, 'description')} />
      </ItemContainer>
      <ItemContainer>
        <ProgramMap
          address={get(program, 'address')}
          addressText={get(program, 'address.text')} />
      </ItemContainer>
      <ItemContainer>
        <ProgramLots
          city={get(program, 'city.name')}
          lots={get(program, 'lots')}
          program={get(program, 'name')} />
      </ItemContainer>
    </>
  )
}

ProgramContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  program: PropTypes.object
}

const ProgramPage = ({ program, loading }) => (
  <Container>
    <ProgramContentContainer>
      <ProgramContent program={program} loading={loading} />
    </ProgramContentContainer>
    <CityForm
      form='brochure'
      city={get(program, 'city.name')}
      loading={loading}
      program={get(program, 'name')}
      programs={program && [program]} />
  </Container>
)

ProgramPage.propTypes = {
  loading: PropTypes.bool.isRequired,
  program: PropTypes.object
}

export default ProgramPage
