import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { replace } from 'lodash'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`

const Title = styled(HomeSectionTitle)`
  margin-bottom: 16px;
`

const ProgramDescription = ({ description }) => (
  <Container>
    <Title>
      Description
    </Title>
    <HomeSectionDescription>
      {replace(description, /<[^>]+>/g, '')}
    </HomeSectionDescription>
  </Container>
)

ProgramDescription.propTypes = {
  description: PropTypes.string
}

ProgramDescription.defaultProps = {
  description: ''
}

export default ProgramDescription
