import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, capitalize } from 'lodash'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ProgramTitle = ({ name, city }) => (
  <Container>
    <HomeSectionTitle tag='h1'>
      {name} à {capitalize(get(city, 'name'))} ({get(city, 'zipcode')})
    </HomeSectionTitle>
  </Container>
)

ProgramTitle.propTypes = {
  name: PropTypes.string,
  city: PropTypes.object
}

ProgramTitle.defaultProps = {
  name: '',
  city: {}
}

export default ProgramTitle
