import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { map, isEqual, nth, isEmpty, uniq } from 'lodash'

import media from '../../../utils/media'
import CarouselWrapper from '../../molecules/CarouselWrapper'
import RandomBackgroundImage from '../../atoms/AdImage'

const StyledImage = styled.img`
  width: 100%;
  height: 400px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  ${({ isDisplayed }) => !isDisplayed && css`opacity: 0;`}
  transition: all 1ms ease-in-out;

  ${media.lessThan('xl')`
    width: 100%;
    height: 389px;
  `}

  ${media.lessThan('sm')`
    width: 100%;
    height: 286px;
  `}
`

const RandomImage = styled(RandomBackgroundImage)`
  margin-top: 16px;
  width: 100%;
  height: 400px;

  ${media.lessThan('xl')`
    width: 100%;
    height: 389px;
  `}

  ${media.lessThan('sm')`
    width: 100%;
    height: 286px;
  `}
`

const ProgramCarousel = ({ imgs }) => {
  const images = useRef(map(uniq(imgs), image => image))
  const [step, updateStep] = useState(0)
  const [isHover, updateIsHover] = useState(false)
  const [isError, updateIsError] = useState(isEmpty(imgs))

  return (
    <CarouselWrapper
      step={step}
      imgs={isError ? [{}] : images.current}
      isHover={isHover}
      updateStep={updateStep}
      updateIsHover={updateIsHover}>
      {isError && <RandomImage format='jpg' />}
      {!isError && map(images.current, (_, idx) => (
        <StyledImage
          src={nth(images.current, idx)}
          key={`image-${idx}`}
          onError={() => updateIsError(true)}
          isDisplayed={isEqual(step, idx)}
          onMouseEnter={() => updateIsHover(true)}
          onMouseLeave={() => updateIsHover(false)} />
      ))}
    </CarouselWrapper>
  )
}

ProgramCarousel.propTypes = {
  imgs: PropTypes.array
}

ProgramCarousel.defaultProps = {
  imgs: []
}

export default ProgramCarousel
