import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, isEmpty, reverse } from 'lodash'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`

const FolhomeeContainer = styled.div`
  width: 100%;
  height: 302px;
  margin-top: 16px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => get(theme, 'blue')};

  & .leaflet-control-attribution {
    display: none;
  }
`

const FolhomeeMap = ({ center }) => {
  const [readyToRender, updateReadyToRender] = useState(false)

  setTimeout(() => {
    updateReadyToRender(true)
  }, 0)

  return (
    <FolhomeeContainer>
      {readyToRender && <MapContainer
        zoom={15}
        style={{ height: 300, borderRadius: 10 }}
        center={center}>
        <TileLayer
          url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors' />
        <Marker position={center} />
      </MapContainer>}
    </FolhomeeContainer>
  )
}

FolhomeeMap.propTypes = {
  center: PropTypes.array
}

const ProgramMap = ({ address, addressText }) => (
  <Container>
    <HomeSectionTitle>
      {isEmpty(addressText) ? get(address, 'label') : addressText}
    </HomeSectionTitle>
    <FolhomeeMap center={[get(address, 'lat'), get(address, 'lng')]} />
  </Container>
)

ProgramMap.propTypes = {
  address: PropTypes.object,
  addressText: PropTypes.string
}

ProgramMap.defaultProps = {
  address: { lat: 0, lng: 0 },
  addressText: ''
}

export default ProgramMap
