import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { groupBy, isEqual, get, map, first, tail, min, reduce, size } from 'lodash'

import Modal from '../../molecules/Modal'
import media from '../../../utils/media'
import theme from '../../../config/theme'
import numeral from '../../../config/number'
import ProgramsForm from '../../molecules/ProgramsForm'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const getPartTitle = (total, long = true) => {
  if (isEqual(total, 0) || isEqual(total, 1)) {
    if (long) {
      return 'Studio/T1'
    }
    return 'Studio'
  }

  return `${total} pièces`
}

const getLotsTitle = lots => {
  if (isEqual(size(lots), 1)) {
    return '1 bien'
  }

  return `${size(lots)} biens`
}

const getFloorTitle = floor => {
  if (isEqual(floor, 0)) {
    return 'Rez de chaussée'
  }

  if (isEqual(floor, 1)) {
    return '1er étage'
  }

  return `${floor}ème étage`
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
`

const RowContainer = styled.div`
  padding: 5px 12px;
  border: 1px solid ${({ theme }) => get(theme, 'blue', '#fff')};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  &:first-of-type {
    margin-top: 16px;
  }
`

const LotPartTitle = styled(HomeSectionSubtitle)`
  margin-top: 12px;
  margin-bottom: 8px;
`

const LotsRowsContainer = styled.div`
  transition: all 100ms ease-in-out;
  ${({ isOpen }) => isOpen ? '' : 'max-height: 0;'}
`

const BaseContainer = styled.div`
  ${({ onClick }) => onClick ? 'cursor: pointer;' : ''}
  align-items: center;
  justify-content: space-between;
  transition: all 250ms ease-in-out;
  display: ${({ isHidden }) => isHidden ? 'none' : 'flex'};
  ${({ isHidden }) => isHidden ? 'max-height: 0;' : ''}
`

const LeftTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const LotTitle = styled(HomeSectionDescription)`
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 16px;

  & > sup {
    vertical-align: super;
    font-size: 12px;
  }

  ${media.lessThan('sm')`
    margin-bottom: 8px;
  `}
`

const PriceTitle = styled(HomeSectionDescription)`
  font-weight: 600;
  margin-bottom: 8px;
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
`

const RightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.lessThan('sm')`
    justify-content: center;
    ${({ column }) => column ? 'flex-direction: column;' : ''}
  `}
`

const LotTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`

const RightIcon = styled(FontAwesomeIcon)`
  margin-left: 28px;
  transition: transform 330ms ease-in-out;
  ${({ isOpen }) => isOpen ? 'transform: rotate(180deg);' : ''}

  ${media.lessThan('sm')`
    margin-left: 8px;
  `}
`

const LotPrice = styled(HomeSectionSubtitle)`
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};

  ${media.lessThan('sm')`
    margin-bottom: 8px;
  `}
`

const PlanButton = styled.button`
  font-family: 'Source Sans Pro';
  cursor: pointer;
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  background-color: ${({ theme }) => get(theme, 'blue', '#fff')};
  padding: 10px 20px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.19;
  border-radius: 5px;
  text-align: center;
  margin-left: 32px;
  z-index: 10;

  ${media.lessThan('sm')`
    margin: 0;
  `}
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

const Separator = styled.div`
  margin-top: 12px;
  width: 100%;
  height: 0.2px;
  background-color: ${({ theme }) => get(theme, 'brownishGrey', '#fff')};
  opacity: 0.5;
`

const LotFloor = ({ floor }) => (
  <RightContainer>
    <ImageContainer>
      <StaticImage src='../../../images/floor.png' alt='étage programme' />
    </ImageContainer>
    <HomeSectionDescription>
      {getFloorTitle(parseInt(floor, 10))}
    </HomeSectionDescription>
  </RightContainer>
)

LotFloor.propTypes = {
  floor: PropTypes.number.isRequired
}

const LotRow = ({ program, city, lot, isOpen }) => {
  const [isModalOpen, updateIsModalOpen] = useState(false)
  const onClose = useCallback(() => updateIsModalOpen(false), [updateIsModalOpen])

  return (
    <>
      {isOpen && <Separator />}
      <BaseContainer isHidden={!isOpen}>
        <LotTitleContainer>
          <LotTitle>
            {getPartTitle(parseInt(get(lot, 'rooms')), false)} • {get(lot, 'surface')}m<sup>2</sup>
          </LotTitle>
          <LotFloor floor={get(lot, 'floor')} />
        </LotTitleContainer>
        <RightContainer column={true}>
          <LotPrice>
            {numeral(min(map(get(lot, 'prices'), 'total'))).format('0,0 $')}
          </LotPrice>
          <PlanButton onClick={(evt) => updateIsModalOpen(true)}>
            Plan
          </PlanButton>
        </RightContainer>
      </BaseContainer>
      <Modal isOpen={isModalOpen} checkClick={true} onClose={onClose}>
        <ProgramsForm
          city={city}
          form='plan'
          floor={get(lot, 'floor')}
          price={get(lot, 'price')}
          rooms={get(lot, 'rooms')}
          surface={get(lot, 'surface')}
          program={program}
          onClose={onClose} />
      </Modal>
    </>
  )
}
LotRow.propTypes = {
  lot: PropTypes.object.isRequired,
  city: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  program: PropTypes.string.isRequired
}

const LotsRows = ({ lots, total, isOpen, program, city }) => map(lots, (lot, idx) => (
  <LotRow
    lot={lot}
    key={`${city}-lots-${idx}-${total}`}
    city={city}
    program={program}
    isOpen={isOpen} />
))

const GroupedPart = ({ total, lots, program, city }) => {
  const [isOpen, updateIsOpen] = useState(false)
  const lowest = reduce(tail(lots),
    (acc, { prices }) => min([acc, min(map(prices, 'total'))]), min(map(get(first(lots), 'prices'), 'total')))
  const toggleIsOpen = () => updateIsOpen(!isOpen)

  return (
    <RowContainer>
      <BaseContainer onClick={toggleIsOpen}>
        <LeftTitleContainer>
          <LotPartTitle>
            {getPartTitle(total)}
          </LotPartTitle>
          <PriceTitle>
            À partir de {numeral(lowest).format('0,0 $')}
          </PriceTitle>
        </LeftTitleContainer>
        <RightContainer>
          <HomeSectionSubtitle>
            {getLotsTitle(lots)}
          </HomeSectionSubtitle>
          <RightIcon icon='chevron-down' color={get(theme, 'blue')} isOpen={isOpen} />
        </RightContainer>
      </BaseContainer>
      <LotsRowsContainer isOpen={isOpen}>
        <LotsRows
          lots={lots}
          city={city}
          total={total}
          isOpen={isOpen}
          program={program} />
      </LotsRowsContainer>
    </RowContainer>
  )
}

GroupedPart.propTypes = {
  lots: PropTypes.array.isRequired,
  city: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  program: PropTypes.string.isRequired
}

const LotsRow = ({ groupedLots, city, program }) => map(groupedLots, (lots, total) => (
  <GroupedPart
    key={`program-lots-${total}`}
    lots={lots}
    city={city}
    total={parseInt(total, 10)}
    program={program} />
))

LotsRow.propTypes = {
  city: PropTypes.string.isRequired,
  program: PropTypes.string.isRequired,
  groupedLots: PropTypes.object.isRequired
}

const ProgramLots = ({ lots, city, program }) => {
  const groupedLots = groupBy(lots, 'rooms')

  return (
    <Container>
      <HomeSectionTitle>
        Disponible à la vente :
      </HomeSectionTitle>
      <LotsRow
        city={city}
        program={program}
        groupedLots={groupedLots} />
    </Container>
  )
}

ProgramLots.propTypes = {
  lots: PropTypes.array,
  city: PropTypes.string,
  program: PropTypes.string
}

ProgramLots.defaultProps = {
  lots: [],
  city: '',
  program: ''
}

export default ProgramLots
